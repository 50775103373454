import React from 'react';
import { Alert } from 'reactstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';

const ErrorAlert = props => {
  const { className, message, errorMsg, innerRef } = props;
  return (
    <Alert
      color="danger"
      className={`w-full text-center ${className || ''}`}
      data-testid="error-alert"
      innerRef={innerRef}
    >
      <FontAwesomeIcon icon={faExclamationCircle} /> {message || errorMsg}
    </Alert>
  );
};

ErrorAlert.propTypes = {
  className: PropTypes.string,
  message: PropTypes.node,
  // (Deprecated) Should use "message" prop
  errorMsg: PropTypes.node,
  innerRef: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.func,
  ]),
};

export default ErrorAlert;
